// src/components/RecoverPassword.js
import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import path from "../config";

function RecoverPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(""); // To store success or error messages

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    try {
      const response = await fetch(path +
        "api/recoverPassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMessage("Verifique seu email para o link de redefinição de senha.");
      } else {
        setMessage(
          data.message || "Falha ao recuperar a senha. Tente novamente."
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setMessage("Ocorreu um erro: " + error.message);
    }
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container className="my-5">
        <Row className="justify-content-center">
          <Col md={6}>
            <Image src="./assets/camera.png" fluid alt="description of image" />
          </Col>
          <Col md={6}>
            <Card className="p-4 shadow-lg border-0 rounded-3">
              <Card.Body>
                <h2 className="mb-4 fw-bold textVet">Recuperar Palavra-Chave</h2>
                {message && (
                  <p className="text-center text-info">{message}</p>
                )}{" "}
                {/* Display success or error message */}
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>Endereço de email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Digitar email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="rounded px-3 py-2"
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Enviar Password por email
                  </Button>
                </Form>
                <div className="text-center mt-3">
                  <Link to="/login">Login</Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RecoverPassword;
