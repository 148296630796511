import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  InputGroup,
  FormControl,
  Pagination,
  Modal,
  Form,
} from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa"; // Import icons for actions
import ProfilePhotoSmallByUserId from "../../Photos/profilephotoSmallByUserId"
import "../../CSS/Userslist.css"
import path from "../../config";

function Users() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    password: "",
    role: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem("userToken");
      try {
        const response = await fetch(path + "user/users", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }

        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    filterUsers(e.target.value);
  };

  const filterUsers = (term) => {
    const filtered = users.filter(
      (user) =>
        (user.username &&
          user.username.toLowerCase().includes(term.toLowerCase())) ||
        (user.email && user.email.toLowerCase().includes(term.toLowerCase()))
    );
    setFilteredUsers(filtered);
    setCurrentPage(1);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const generatePassword = () => {
    const length = 8;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const handleAddUser = async () => {
    const password = generatePassword();
    const newUserWithPassword = { ...newUser, password };

    try {
      const response = await fetch(path + "user/addUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify(newUserWithPassword),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Error adding user");
      }

      const data = await response.json();
      setUsers([...users, data]);
      setFilteredUsers([...filteredUsers, data]);
      setShowModal(false);
      setError("");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center mb-4">

        <Button
          variant="primary"
          onClick={() => setShowModal(true)}
          className="d-flex align-items-center justify-content-center gap-2"
          style={{ padding: "10px 15px", fontSize: "1rem" }}
        >
          Novo utilizador
          <FaPlus size={18} color="white" />
        </Button>
        <InputGroup className="w-50">
          <FormControl
            placeholder="Procurar por nome ou email"
            value={searchTerm}
            onChange={handleSearch}
          />
        </InputGroup>
      </div>

      <Table hover responsive className="shadow-sm table-modern no-border">
        <thead className="bg-secondary text-white align-middle">
          <tr>
            <th className="text-center" style={{ width: "5%" }}>ID</th>
            <th className="text-center" style={{ width: "15%" }}>Photo</th>
            <th style={{ width: "25%" }}>Nome</th>
            <th style={{ width: "30%" }}>Email</th>
            <th className="text-center" style={{ width: "10%" }}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td className="text-center">{user.id}</td>
              <td className="text-center"><ProfilePhotoSmallByUserId userid={user.id} /></td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td className="text-center">
                <td class="d-flex justify-content-center align-items-center">
                  <div size="lg" className="action-btn edit-btn" title="Editar">
                    <FaEdit />
                  </div>
                  <div size="lg" className="action-btn delete-btn" title="Apagar">
                    <FaTrash />
                  </div>
                </td>
              </td>

            </tr>
          ))}
        </tbody>
      </Table>


      <Pagination className="justify-content-center my-4">
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePaginationClick(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar novo utilizador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <div className="text-danger mb-3">{error}</div>}
          <Form>
            <Form.Group controlId="formUsername" className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={newUser.username}
                onChange={(e) =>
                  setNewUser({ ...newUser, username: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={newUser.email}
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formRole" className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                value={newUser.role}
                onChange={(e) =>
                  setNewUser({ ...newUser, role: e.target.value })
                }
              >
                <option value="">Selecionar Role</option>
                <option value="Client">Client2</option>
                <option value="Anesthetist">Anesesista</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleAddUser}>
            Adicionar Utilizador
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Users;
