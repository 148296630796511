import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CSS/NotFound.css"; // Vamos criar uma folha de estilo separada

function NotFound() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="not-found-container">
      <div className="error-content">
        <img
          src="./assets/newlogo.jpeg"
          alt="404 Not Found"
          className="error-image"
          style = {{height:"auto", width:"250px"}}
        />
        <h1 className="error-title">Oops! Esta página não existe</h1>
        <p className="error-message">
          Tenta encontrar o que procuras utilizando a <br /> barra de navegação
          ou volta para a página inicial.
        </p>
        <Button variant="primary" onClick={goHome} className="home-button">
          Voltar para a página inicial
        </Button>
      </div>
    </div>
  );
}

export default NotFound;
