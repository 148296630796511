// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ children, requiredRoles }) => {
  const token = localStorage.getItem('userToken');

  const isAuthenticated = () => {
    if (!token) return false; // No token, not authenticated

    try {
      const decodedToken = jwtDecode(token); // Decode the token
      const currentTime = Date.now() / 1000; // Current time in seconds

      // Check if token is expired
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem('userToken'); // Remove expired token
        return false; // Token is expired
      }
      
      return decodedToken; // Return the decoded token for role checking
    } catch (error) {
      console.error('Token is invalid:', error);
      return false; // Token is invalid
    }
  };

  const decodedToken = isAuthenticated();

  // Check if the user has one of the required roles
  const hasRequiredRole = requiredRoles
    ? requiredRoles.includes(decodedToken?.role) // Check if the user role matches
    : true; // If no roles are required, allow access

  return decodedToken && hasRequiredRole ? children : <Navigate to="/dashboard" />;
};

export default PrivateRoute;
