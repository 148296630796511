import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import Home from "./components/Home";
import Services from "./components/Services";
import Contact from "./components/Contact";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import Login from "./components/Login"; // Import the Login component
import Footer from "./components/Footer";
import Register from "./components/Register";
import CookieBar from "./components/CookieBar";
import Dashboard from "./Admin/Dashboard.js";
import AgreementClient from "./Admin/AgreementClient.js";
import AgreementAnestetist from "./Admin/AgreementAnestetist.js";
import DashboardClient from "./Client/Dashboard.js";
import DashboardAnesthesist from "./Anesthesist/Dashboard.js";
import PrivateRoute from "./components/PrivateRoute"; // Import the PrivateRoute
import ChangePassword from "./components/users/userpassreset.js";
import UserAgreement from "./components/users/useragreement.js";
import UserProfilePhoto from "./Photos/profilephoto"; // Import the UserProfilePhoto component

// Users
import Users from "./components/users/userslist"; // Import the Users component
import UserProfile from "./userprofile/MyProfile.js"; // Import the Users component
import RecoverPassword from "./Authorization/RecoverPassword.js"; //Import the Recover Password componente

// Internal
import AvailabilityInternal from "./backoffice/Internal/Availability"; // Import the Users component
import Scheduler from "./backoffice/Internal/Scheduler.js";

//Shcedule Detail
import ScheduleDetails from "./Scheduler/ScheduleDetail.js";

import { jwtDecode } from "jwt-decode";
import "./App.css"; // Import your CSS

import NotFound from "./Error/NotFound.js"; 

function App() {
  const { t } = useTranslation();

  // Manage the authentication state and user role
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    role: null,
    passwordReseted: false,
    username: "", // Full name
    firstName: "", // Extracted first name
  });

  // Function to check if the token is valid and return user role
  const checkAuthentication = () => {
    const token = localStorage.getItem("userToken");
    if (!token)
      return {
        isAuthenticated: false,
        role: null,
        username: "",
        firstName: "",
      };

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        localStorage.removeItem("userToken");
        return {
          isAuthenticated: false,
          role: null,
          username: "",
          firstName: "",
        };
      }

      const fullName = decodedToken.username; // Assuming your token has a `username` field
      const firstName = fullName.split(" ")[0]; // Get the first name

      return {
        isAuthenticated: true,
        role: decodedToken.role,
        username: fullName,
        firstName: firstName, // Set first name
      };
    } catch (error) {
      console.error("Token is invalid:", error);
      return {
        isAuthenticated: false,
        role: null,
        username: "",
        firstName: "",
      };
    }
  };

  // Use useEffect to update the authentication status when the app loads
  useEffect(() => {
    setAuthState(checkAuthentication());
  }, []); // Empty array means this will only run on mount

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem("userToken"); // Remove the token
    setAuthState({
      isAuthenticated: false,
      role: null,
      username: "",
      firstName: "",
    }); // Update authentication state
    window.location.href = "/"; // Redirect to home page
  };

  return (
    <Router>
      <div className="app-container">
        <Navbar bg="white" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img
                src="/assets/newlogo.jpeg"
                width="250"
                className="d-inline-block align-top"
                alt="DS"
              />{" "}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto d-flex align-items-center">
                {!authState.isAuthenticated ? (
                  <>
                    <Nav.Link href="/">{t("Vetanesthesia")}</Nav.Link>
                    <Nav.Link href="/services">{t("Services")}</Nav.Link>
                    <Nav.Link href="/contact">{t("Contact")}</Nav.Link>
                  </>
                ) : null}
                {authState.isAuthenticated ? (
                  <>
                    {authState.role === "Client" ? (
                      <Nav.Link href="/dashboardclient">
                        {t("Dashboard")}
                      </Nav.Link>
                    ) : authState.role === "Anesthetist" ? (
                      <Nav.Link href="/dashboardanesthetist">
                        {t("Dashboard")}
                      </Nav.Link>
                    ) : (
                      <Nav.Link href="/dashboard">{t("Dashboard")}</Nav.Link>
                    )}

                    {/* Conditional links based on user role */}
                    {authState.role === "Admin" && (
                      <>
                        <Nav.Link href="/UserList">{t("Users")}</Nav.Link>
                        {/* Agreement Dropdown Menu */}
                        <NavDropdown
                          title={t("Agreement")}
                          id="agreement-dropdown"
                        >
                          <NavDropdown.Item href="/AgreementClient">
                            {t("Acordo Cliente")}
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/AgreementAnestetist">
                            {t("Acordo Anestesista")}
                          </NavDropdown.Item>
                        </NavDropdown>
                      </>
                    )}
                    {authState.role === "Client" && (
                      <Nav.Link href="/Client">{t("Client Area")}</Nav.Link>
                    )}
                    {authState.role === "Anesthetist" && (
                      <Nav.Link href="/AvailabilityInternal">
                        {t("Anesthetist Area")}
                      </Nav.Link>
                    )}

                    {/* New Options Dropdown Menu */}
                    <NavDropdown title={t("Opções")} id="options-dropdown">
                      {/* Display first name with hover for full name */}
                      <NavDropdown.Item disabled title={authState.username}>
                        {t("Autenticado como ")}: {authState.firstName}
                      </NavDropdown.Item>
                      {(authState.role === "Anesthetist" || authState.role === "Client") && (
                        <NavDropdown.Item href="/MyProfile">
                          {t("My Profile")}
                        </NavDropdown.Item>
                      )}
                      <NavDropdown.Item href="/ChangePassword">
                        {t("Change Password")}
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleLogout}>
                        {t("Logout")}
                      </NavDropdown.Item>
                    </NavDropdown>

                    {/* Profile Thumbnail */}
                    <UserProfilePhoto></UserProfilePhoto>
                  </>
                ) : (
                  <Nav.Link href="/login">{t("Login")}</Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="content-wrap">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login onLogin={setAuthState} />} />
            <Route path="/RecoverPassword" element={<RecoverPassword />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute requiredRoles={["Admin"]}>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboardclient"
              element={
                <PrivateRoute requiredRoles={["Client"]}>
                  <DashboardClient />
                </PrivateRoute>
              }
            />
            <Route
              path="/UserList"
              element={
                <PrivateRoute requiredRoles={["Admin"]}>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/AgreementClient"
              element={
                <PrivateRoute requiredRoles={["Admin"]}>
                  <AgreementClient />
                </PrivateRoute>
              }
            />
            <Route
              path="/AgreementAnestetist"
              element={
                <PrivateRoute requiredRoles={["Admin"]}>
                  <AgreementAnestetist />
                </PrivateRoute>
              }
            />
            <Route
              path="/Client"
              element={
                <PrivateRoute requiredRoles={["Client"]}>
                  <Scheduler />
                </PrivateRoute>
              }
            />
            <Route
              path="/AvailabilityInternal"
              element={
                <PrivateRoute requiredRoles={["Anesthetist"]}>
                  <AvailabilityInternal />
                </PrivateRoute>
              }
            />
            <Route
              path="/MyProfile"
              element={
                <PrivateRoute requiredRoles={["Anesthetist", "Client"]}>
                  <UserProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/ChangePassword"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/useragreement"
              element={
                <PrivateRoute requiredRoles={["Anesthetist", "Client"]}>
                  <UserAgreement />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboardanesthetist"
              element={
                <PrivateRoute requiredRoles={["Anesthetist"]}>
                  <DashboardAnesthesist />
                </PrivateRoute>
              }
            />
            <Route
              path="/Schedule/:id"
              element={
                <PrivateRoute requiredRoles={["Anesthetist","Client","Admin"]}>
                  <ScheduleDetails/>
                </PrivateRoute>
              }
            />
            {/* Rota 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
        <CookieBar />
      </div>
    </Router>
  );
}

export default App;
