// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LanguageSwitcher from './LanguageSwitcher'; // Import the language switcher
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4} className="footer-section">
            <h5>Sobre a DS VetAnesthesia</h5>
            <p>
            Com vasta experiência em anestesia veterinária, a DS VetAnesthesia nasceu com o propósito de que mais animais possam ser melhor anestesiados.
            </p>
            <p>A nossa equipa é composta por médicos veterinários anestesistas dedicados e apaixonados pelo bem-estar e segurança animal.  
            </p>
          </Col>
          <Col md={4} className="footer-section">
            <h5>Links rápidos</h5>
            <ul className="footer-links">
              <li><a href="/">{t('Vetanesthesia')}</a></li>
              <li><a href="/services">{t('Services')}</a></li>
              <li><a href="/contact">{t('Contact')}</a></li>
            </ul>
          </Col>
          <Col md={4} className="footer-section">
            <h5>Contacte-nos</h5>
            <p>Email: support@dsvetanesthesia.com</p>
            <p>Telefone: </p>
            <div className="social-icons">
              <a href="https://www.facebook.com/dsvetanesthesia"><i className="fab fa-facebook"></i></a>
              <a href="https://www.instagram.com/ds_vetanesthesia/profilecard/?igsh=MW1lenZjNzUzdHZwaQ=="><i className="fab fa-instagram"></i></a>
              <a href="https://twitter.com"><i className="fab fa-twitter"></i></a>
            </div>
          </Col>
        </Row>
        <LanguageSwitcher /> {/* Add the Language Switcher */}
        <Row className="text-center mt-4">
          <Col>
            <p>&copy; {new Date().getFullYear()} DS VetAnesthesia. Todos os direitos reservados.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
