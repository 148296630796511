// src/components/Agreement.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Spinner, Alert } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode to decode the token
import {  useNavigate } from 'react-router-dom';
import path from "../../config"

function Agreement() {
  const [agreementContent, setAgreementContent] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  // Decode token to get user role
  const token = localStorage.getItem('userToken');
  const userRole = token ? jwtDecode(token).role : null;
  const decodedToken = jwtDecode(token);

  // Fetch agreement content based on user role
  useEffect(() => {
    const fetchAgreement = async () => {
      try {
        const response = await fetch(path + `agreement/GetAgreementContent?role=${userRole}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch agreement');
        }

        const data = await response.json();
        setAgreementContent(data.content); // Assuming this is the HTML content from CKEditor
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (userRole) {
      fetchAgreement();
    } else {
      setError('User role not found');
      setLoading(false);
    }
  }, [userRole, token]);

  // Handle scrolling to the bottom of the agreement
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setIsButtonEnabled(true); // Enable button when scrolled to the bottom
    }
  };

  // Handle "Agree" button click to confirm agreement
  const handleAgree = async () => {
    try {
      const response = await fetch(path + 'agreement/UpdateUserConditions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ role: userRole, dateOfAcceptance: new Date().toISOString(),ConditionsAccepted:agreementContent }),
      });

      if (!response.ok) {
        throw new Error('Failed to confirm agreement acceptance');
      }
      if (decodedToken.role === 'Client') {
          navigate('/dashboardclient'); // Redirect to client dashboard
      } else if (decodedToken.role === 'Anesthetist') {
        navigate('/dashboardanestethist'); // Redirect to client dashboard
      }else{
        navigate('/dashboard'); // Redirect to the regular dashboard
      }
     
    } catch (error) {
      console.error('Error confirming agreement:', error);
      setError('An error occurred while confirming the agreement.');
    }
  };

  // Render loading, error, or agreement content
  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Termos e condições Condições de aceitação</h2>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">A carregar informação...</span>
                  </Spinner>
                </div>
              ) : error ? (
                <Alert variant="danger" className="text-center">
                  {error}
                </Alert>
              ) : (
                <div
                  style={{
                    maxHeight: '300px',
                    overflowY: 'scroll',
                    border: '1px solid #ddd',
                    padding: '15px',
                    marginBottom: '15px'
                  }}
                  onScroll={handleScroll}
                  dangerouslySetInnerHTML={{ __html: agreementContent }} // Set the HTML content here
                />
              )}
              <Button
                variant="primary"
                disabled={!isButtonEnabled || loading || error}
                onClick={handleAgree}
                className="w-100"
              >
                Aceitar termos e condições
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Agreement;
