import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Spinner, Alert } from 'react-bootstrap'; // Import Bootstrap components
import ProfilePhotoSmallByUserId from '../Photos/profilephotoSmallByUserId';
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa';
import path from '../config';

const ScheduleInfo = ({ scheduleId }) => {
  const [schedule, setSchedule] = useState(null); // State to store the schedule
  const [loading, setLoading] = useState(true); // State to control loading
  const [error, setError] = useState(null); // State for errors

  const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch schedule data
    const fetchSchedule = async () => {
      try {
        const response = await axios.get(path + `sche/GetScheduleById/${scheduleId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`, // Assuming token is stored in localStorage
          },
        });
        setSchedule(response.data); // Update the schedule data in the state
      } catch (error) {
        console.error('Error fetching schedule:', error);
        setError('Failed to fetch schedule.'); // Update the error state
      } finally {
        setLoading(false); // Set loading to false after the fetch
      }
    };

    fetchSchedule();
  }, [scheduleId]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status" />
        <span className="ms-2">Loading schedule details...</span>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="text-center">
        {error}
      </Alert>
    );
  }

  // Render schedule details in a card
  return (
    <>
    <div style={{ width: '100%' }}>
      <button
        onClick={() => navigate(-1)}  // Voltar para a página anterior
        className="btn btn-link"
        style={{
          color: '#33648A',
          fontSize: '20px',
          padding: '0',
          width: '100%', // Faz o botão ocupar a linha inteira
          textAlign: 'left' // Alinha o texto à esquerda
        }}
      >
        <FaArrowLeft /> Voltar
      </button>
    </div>
    
    <div className="container">
      <h2 className="mb-4 fw-bold textVet">Detalhes da marcação</h2>
      {schedule ? (
        <Card>
          <Card.Body>
            <Card.Text>
              <ProfilePhotoSmallByUserId  userid={schedule.creator.id}/>
              <strong>Criado por:</strong> {schedule.creator.username} ({schedule.creator.email})<br />
              <strong>Assestido por:</strong> {schedule.associatedUser?.username || 'N/A'} ({schedule.associatedUser?.email || 'N/A'})<br />
              <strong>Data de inicio:</strong> {new Date(schedule.startDate).toLocaleString()}<br />
              <strong>Data de fim:</strong> {new Date(schedule.endDate).toLocaleString()}<br />
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (
        <Alert variant="warning" className="text-center">Schedule data not found.</Alert>
      )}
    </div>
    </>
  );
};

export default ScheduleInfo;
