import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import moment from "moment-timezone";
import UserProfilePhoto from "../Photos/profilephotoBig"; // Import the UserProfilePhoto component
import "../CSS/MyProfile.css"
import path from "../config";

function UserProfile() {
  const [formData, setFormData] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    country: "",
    stateOrProvince: "",
    city: "",
    postalCode: "",
    timezone: "",
    utcOffset: 0,
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [loadingConditions, setLoadingConditions] = useState(true);

  const [profileImage, setProfileImage] = useState(null);
  const [photoLoading, setPhotoLoading] = useState(false);
  const [photoSubmitted, setPhotoSubmitted] = useState(false);
  const [photoError, setPhotoError] = useState("");
  const [refreshPhoto, setRefreshPhoto] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("userToken");
      try {
        const response = await fetch(
          path + "user/getuserprofile",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user profile.");
        }

        const data = await response.json();
        if (data && data.timezone) {
          setFormData((prevData) => ({
            ...data,
            timezone: data.timezone || "",
          }));
        }
      } catch (err) {
        console.error(err);
        setError("Failed to load user profile.");
      }
    };

    const fetchTimezones = () => {
      const timezoneList = moment.tz.names();
      setTimezones(timezoneList);
    };

    const fetchUserConditions = async () => {
      // Fetch user conditions logic
      const token = localStorage.getItem("userToken");
      try {
        const response = await fetch(
          path + "agreement/GetUserConditions",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user conditions.");
        }

        const data = await response.json();
        console.log("Conditions Data:", data); // Log the data to check the structure
        setConditions(data || []); // Set to empty array if data is null or undefined
      } catch (err) {
        console.error(err);
        setError("Failed to load user conditions.");
      } finally {
        setLoadingConditions(false);
      }
    };

    fetchUserProfile();
    fetchTimezones();
    fetchUserConditions();
  }, []);

  const handleImageChange = (event) => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setProfileImage(file);
    } else {
      console.error("No file selected or invalid event.");
    }
  };

  const calculateUtcOffset = (timezone) => {
    const date = new Date();
    const utcDate = new Date(
      date.toLocaleString("en-US", { timeZone: timezone })
    );
    const offset = Math.floor(
      (utcDate.getTime() - date.getTime()) / (1000 * 60 * 60)
    );
    return offset;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "timezone") {
      const utcOffset = calculateUtcOffset(value);
      setFormData((prevData) => ({ ...prevData, [name]: value, utcOffset }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("userToken");

    try {
      const response = await fetch(path + "user/updateprofile", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Error updating profile");
      }

      setSubmitted(true);
      setError("");
    } catch (err) {
      console.error(err);
      setError("Failed to update profile, please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoSubmit = async () => {
    if (!profileImage) {
      setPhotoError("Please select a photo before uploading.");
      return;
    }

    setPhotoLoading(true);
    const token = localStorage.getItem("userToken");

    try {
      const formData = new FormData();
      formData.append("profilePhoto", profileImage);

      const response = await fetch(path + "photo/uploadphoto", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Error uploading profile photo");
      }
      
      if (response.ok) {
        // Atualize o estado para forçar a atualização do componente de foto
        setRefreshPhoto(prev => !prev);
      }
      const data = await response.json(); // Supondo que o servidor retorne a URL da imagem
      setPhotoSubmitted(true);
      setPhotoError("");
      setProfileImage(null); // Limpa a imagem carregada
    } catch (err) {
      console.error(err);
      setPhotoError("Failed to upload photo, please try again.");
    } finally {
      setPhotoLoading(false);
    }
  };

  return (
    <Container>
      <h2 className="text-center mb-4 fw-bold textVet">Atualizar dados do perfil</h2>
      <Row className="justify-content-center">
        <Col md={12}>
          <Tabs
            defaultActiveKey="profile"
            id="user-profile-tabs"
            className="mb-3"
          >
            <Tab eventKey="profile" title="Perfil">
              <Form onSubmit={handleSubmit} className="modern-form">
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-4" controlId="formName">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Digite seu nome"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formAddressLine1">
                      <Form.Label>Endereço Linha 1</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine1"
                        placeholder="Digite seu endereço"
                        value={formData.addressLine1}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formCountry">
                      <Form.Label>País</Form.Label>
                      <Form.Control
                        type="text"
                        name="country"
                        placeholder="Digite seu país"
                        value={formData.country}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formCity">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        placeholder="Digite sua cidade"
                        value={formData.city}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formPostalCode">
                      <Form.Label>Código Postal</Form.Label>
                      <Form.Control
                        type="text"
                        name="postalCode"
                        placeholder="Digite seu código postal"
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-4" controlId="formAddressLine2">
                      <Form.Label>Endereço Linha 2 (opcional)</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine2"
                        placeholder="Digite sua linha de endereço 2"
                        value={formData.addressLine2}
                        onChange={handleChange}
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formAddressLine3">
                      <Form.Label>Endereço Linha 3 (opcional)</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine3"
                        placeholder="Digite sua linha de endereço 3"
                        value={formData.addressLine3}
                        onChange={handleChange}
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formStateOrProvince"
                    >
                      <Form.Label>Estado ou Província (opcional)</Form.Label>
                      <Form.Control
                        type="text"
                        name="stateOrProvince"
                        placeholder="Digite seu estado ou província"
                        value={formData.stateOrProvince}
                        onChange={handleChange}
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formTimezone">
                      <Form.Label>Fuso Horário</Form.Label>
                      <Form.Control
                        as="select"
                        name="timezone"
                        value={formData.timezone}
                        onChange={handleChange}
                        required
                        className="form-input"
                      >
                        <option value="">Selecione um fuso horário</option>
                        {timezones.map((tz) => (
                          <option key={tz} value={tz}>
                            {tz}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  type="submit"
                  className="submit-button w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner as="span" animation="border" size="sm" />
                  ) : (
                    "Atualizar Perfil"
                  )}
                </Button>
              </Form>
              {submitted && (
                <div className="text-success mt-3">
                  Perfil atualizado com sucesso!
                </div>
              )}
              {error && <div className="text-danger mt-3">{error}</div>}
            </Tab>
            <Tab eventKey="photo" title="Foto de Perfil">
              <div className="text-center">
                <UserProfilePhoto refresh={refreshPhoto} />
              </div>

              <Form.Group className="text-center">
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="form-file-input mb-2 mx-auto"
                  style={{ width: 'auto', marginTop: '18px'}}
                />
                <div className="mt-2">
                  <Button
                    variant="primary"
                    onClick={handlePhotoSubmit}
                    disabled={photoLoading}
                  >
                    {photoLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Enviar"
                    )}
                  </Button>
                </div>
                {photoError && <p className="text-danger">{photoError}</p>}
                {photoSubmitted && (
                  <p className="text-success mt-2">
                    Foto de perfil atualizada com sucesso!
                  </p>
                )}
              </Form.Group>
            </Tab>
            <Tab eventKey="conditions" title="Condições">
              {loadingConditions ? (
                <Spinner animation="border" />
              ) : (
                <div>
                  {conditions.length > 0 ? (
                    conditions.map((condition, index) => (
                      <div key={index} className="condition-card mb-3 p-3">
                        <div style={{ textAlign: "right", color: "green" }}>
                          <strong>Data de Aceitação:</strong>{" "}
                          {moment(condition.DateOfAcceptance).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                        <hr
                          style={{ margin: "10px 0", border: "1px solid #ccc" }}
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: condition.ConditionsAccepted,
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <div>
                      Nenhuma condição encontrada. Total de condições:{" "}
                      {conditions.length}
                    </div>
                  )}
                </div>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export default UserProfile;
