import React, { useState, useEffect } from "react";
import { Button, Spinner, Alert, Container } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import path from "../config";

function Agreement() {
      const [content, setContent] = useState(""); // State to hold editor content
    const [loading, setLoading] = useState(true); // Loading state for fetching content
    const [saving, setSaving] = useState(false); // Loading state for saving content
    const [error, setError] = useState(null); // State for error handling
  
    // Fetch agreement content on component load
    useEffect(() => {
      const fetchContent = async () => {
        setLoading(true);
        try {
          const response = await fetch(path + "agreement/GetAgreementContent?role=Anesthetist", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
          
          if (!response.ok) throw new Error("Failed to load agreement content");
  
          const data = await response.json();
          setContent(data.content);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchContent();
    }, []);
  
    // Function to handle save operation
    const handleSaveContent = async () => {
      setSaving(true);
      try {
        const response = await fetch(path + "agreement/UpdateAgreementContent", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify({ content, role: "Anesthetist" }),
        });
  
        if (!response.ok) throw new Error("Failed to save agreement content");
  
        alert("Content saved successfully!");
      } catch (error) {
        setError(error.message);
      } finally {
        setSaving(false);
      }
    };
  
    return (
      <Container className="my-5">
        <h3 className="fw-bold textVet">Declaração e Garantia de Aceitação - Modelo - Anestesista</h3>
        {loading ? (
          <Spinner animation="border" />
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <>
            <CKEditor
              editor={ClassicEditor}
              data={content}
              onChange={(event, editor) => setContent(editor.getData())}
            />
            <Button
              variant="primary"
              className="mt-3"
              onClick={handleSaveContent}
              disabled={saving}
            >
              {saving ? "A Salvar declaração modelo..." : "Salvar declaração modelo"}
            </Button>
          </>
        )}
      </Container>
    );
}

export default Agreement;
