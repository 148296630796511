// src/components/LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { changeLanguage } from '../i18n';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const handleSelect = (e) => {
    changeLanguage(e);
  };

  return (
    <Dropdown onSelect={handleSelect} className="language-switcher">
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        {i18n.language === 'en' ? 'English' : 'Português'}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {/* <Dropdown.Item eventKey="en">English</Dropdown.Item> */}
        <Dropdown.Item eventKey="pt">Português</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageSwitcher;