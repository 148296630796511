import React, { useState, useEffect } from "react";
import { Container, Card, Button, Modal } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt"; // Portuguese locale
import axios from "axios"; // Axios for API requests
import { format } from "date-fns"; // Import the date formatting function
import './calendar.css';
import path from "../../config";

const Calendar = () => {
  const [schedules, setschedules] = useState([]);
  const [businessHours, setBusinessHours] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null); // For storing selected date range
  const [showModal, setShowModal] = useState(false); // Modal state
  const [showModalDelete, setShowModalDelete] = useState(false); // Modal state
  const [error, setError] = useState("");
  const [currentEvent, setCurrentEvent] = useState(null); // To store the current event being edited
  const [isSchedulesEmpty, setIsSchedulesEmpty] = useState(false); // Verifica se não há eventos

  // New state variables to store the current visible date range
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");
  //Fetch shedules
  // Function to fetch availability
  const fetchSchedules = async (startDate, endDate) => {
    try {
      const token = localStorage.getItem("userToken"); // Assumes JWT token is stored in localStorage

      const response = await axios.get(
        path + `sche/GetmySchedules?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request
            "Content-Type": "application/json",
          },
        }
      );

        // Map schedule data to FullCalendar events format
        const schedules = response.data.map((schedule) => ({
          id: schedule.id, // Ensure the event has an id
          title: getByTitle(schedule).title,
          start: `${schedule.startDate}`, // Full date and time
          end: `${schedule.endDate}`,
          allDay: false,
          extendedProps: {
            description: getByTitle(schedule).description, // Add the description here
          },
          // Add color properties
          backgroundColor: getBackgroundColor(schedule), // Function to determine the background color
          // borderColor: getBorderColor(schedule), // Optional: Set the border color
          // textColor: getTextColor(schedule), // Optional: Set the text color
        }));

      setschedules(schedules); // Set the events for FullCalendar
      setError(""); // Clear errors
    } catch (error) {
      setError(
        "Failed to fetch availability. Please check the date range and try again." +
          error
      );
    }
  };

  // Example functions to return colors based on criteria
  const getBackgroundColor = (schedule) => {
    // Example: Assign color based on some property in the schedule (e.g., userId, type)
    if (schedule.associatedwithuserId === null) {
      return "#E5CF5D"; // User 1's schedules are blue
    } else {
      return "#12747D"; // Default color
    }
  };

  // Example functions to return colors based on criteria
  const getByTitle = (schedule) => {
    if (schedule.associatedwithuserId === null) {
      return {
        title: "Por atribuir", // Title for the event
        //description: "Este evento ainda não está atribuído a um anestesista.", // Additional info
      };
    } else {
      return {
        title: `Confirmado com: ${schedule.associatedUser.username}`, // Title for the event
        //description: "Este evento está confirmado.", // Additional info
      };
    }
  };
  
  

  // Fetch business hours based on selected date range
  const fetchBusinessHours = async (startDate, endDate) => {
    try {
      const token = localStorage.getItem("userToken"); // Assuming the JWT is stored here

      const response = await axios.get(
        path + `avail/Getavailability`,
        {
          params: { startDate, endDate }, // Pass startDate and endDate as query parameters
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token for authentication
            "Content-Type": "application/json",
          },
        }
      );
      setBusinessHours(response.data); // Assuming response.data is an array of business hours
      setIsSchedulesEmpty(response.data.length === 0); // Define se o calendário está vazio
    } catch (error) {
      console.error("Error fetching business hours:", error);
    }
  };

  // Fetch business hours when the date range changes
  const handleDatesSet = (dateInfo) => {
    const startDate = format(new Date(dateInfo.startStr), "yyyy-MM-dd"); // Format start date as YYYY-MM-DD
    const endDate = format(new Date(dateInfo.endStr), "yyyy-MM-dd"); // Format end date as YYYY-MM-DD

    // Update the current visible date range
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);

    fetchBusinessHours(startDate, endDate); // Fetch business hours for the new date range
    fetchSchedules(startDate, endDate); // Fetch Schedules for the new date range
  };

  const handleDateSelect = (selectInfo) => {
    const { start, end } = selectInfo;

    // Get the day of the week for the selected date
    const dayOfWeek = start.getDay();

    // Get the allowed hours for the selected day
    const allowedHoursList = businessHours.filter((bh) =>
      bh.daysOfWeek.includes(dayOfWeek)
    );

    const startHour = start.getHours();
    const endHour = end.getHours();

    // Validate selected time against allowed hours
    // Check if the selected time is within any of the allowed hours for the day
    const isWithinAllowedHours = allowedHoursList.some(
      (allowedHours) =>
        startHour >= parseInt(allowedHours.startTime.split(":")[0], 10) &&
        startHour < parseInt(allowedHours.endTime.split(":")[0], 10) &&
        endHour > parseInt(allowedHours.startTime.split(":")[0], 10) &&
        endHour <= parseInt(allowedHours.endTime.split(":")[0], 10)
    );

    if (isWithinAllowedHours) {
      setSelectedRange(selectInfo); // Store the selected date range
      setShowModal(true); // Show the modal
    } else {
      alert(`Please select a valid available time on this day.`);
    }
  };

  const handleSchedule = async () => {
    try {
      const token = localStorage.getItem("userToken"); // Get the JWT token

      // Create the event object
      const newEvent = {
        startDate: selectedRange.start.toISOString(), // Convert date to ISO format
        endDate: selectedRange.end.toISOString(),
      };

      // Send a POST request to save the event to the database
      const response = await axios.post(
        path + "sche/SetmySchedule/",
        newEvent,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token for authentication
            "Content-Type": "application/json",
          },
        }
      );

      await fetchSchedules(currentStartDate, currentEndDate);
      // If the request is successful, add the new event to the calendar
      setShowModal(false); // Close the modal
      setSelectedRange(null); // Clear the selected range
      setError(""); // Clear any previous errors
    } catch (error) {
      console.error("Failed to schedule the event:", error);
      setError("Failed to schedule the event. Please try again.");
    }
  };

  const isSelectable = () => {
    return businessHours.length > 0; // Calendar is selectable only if there are business hours
  };

  // Handle scheduling Delete
  const handleScheduleDelete = async () => {
    try {
      const token = localStorage.getItem("userToken"); // Assuming the JWT is stored here

      await axios.delete(
        path + `sche/DeleteSchedule/${currentEvent.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token for authentication
            "Content-Type": "application/json",
          },
        }
      );

      // Refresh availability after saving
      await fetchSchedules(currentStartDate, currentEndDate);
      setShowModalDelete(false); // Close the modal
      setCurrentEvent(null);
      setError(""); // Clear any previous errors
    } catch (error) {
      setError("Cannot delete the selected event.");
    }
  };

  // Handle click on a scheduled event
  const handleEventClick = (eventClickInfo) => {
    const { event } = eventClickInfo;
    const { start, end } = event; // Get event details
    setSelectedRange({ start, end }); // Set the selected range
    setShowModalDelete(true); // Show the modal
    setCurrentEvent(event); // Set the current event for modification
  };

  return (
    <Container>
      <h2 className="text-center mb-4 fw-bold textVet">Os meus agendamentos</h2>
      <h5 className="text-center mb-4">
        Só existem Anestesistas disponíveis nos slots permitidos
      </h5>

      {error && <div className="text-danger">{error}</div>}

      <Card>
        <Card.Body>
          <FullCalendar
            plugins={[interactionPlugin, timeGridPlugin]} // Include both plugins
            initialView="timeGridWeek"
            locale={ptLocale} // set the locale to Portuguese
            events={schedules}
            selectable={isSelectable()}
            editable={true}
            height="auto"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek,timeGridDay", // Allow switching between week and day views
            }}
            select={handleDateSelect}
            businessHours={businessHours} // Display business hours on the calendar
            slotDuration="00:30:00" // 30-minute slots for better visibility
            slotLabelInterval="01:00" // Show hour labels
            nowIndicator={true} // Show a line indicating the current time
            slotMinTime="06:00:00" // Set minimum time to 6 AM
            slotMaxTime="19:00:00" // Set maximum time to 7 PM
            allDaySlot={false} // Hide "all-day" slot
            datesSet={handleDatesSet} // Trigger when the visible date range changes
            eventClick={handleEventClick} // Handle event click
            eventContent={(arg) => {
              const description = arg.event.extendedProps.description;
              return (
                <div>
                  <strong>{arg.event.title}</strong>
                  <div style={{ marginTop: '5px' }}>{description}</div>
                </div>
              );
            }}
          />
        {isSchedulesEmpty && (
          <div className="watermark">Sem disponibilidade para esta semana</div>
        )}
      </Card.Body>
    </Card>

      {/* Modal for scheduling confirmation */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Marcar um novo evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Deseja agendar um evento de {selectedRange?.start.toLocaleString()}{" "}
            a {selectedRange?.end.toLocaleString()}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSchedule}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for scheduling confirmation */}
      <Modal show={showModalDelete} onHide={() => setShowModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Remover disponibilidade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Quer remover a sua disponibilidade de{" "}
            {selectedRange?.start.toLocaleString()} a{" "}
            {selectedRange?.end.toLocaleString()}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalDelete(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleScheduleDelete}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Calendar;
