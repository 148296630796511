import React from "react";
import ScheduleInfo from "./SchedulerInfo";
import ScheduleNotes from "./ScheduleNotes";
import ScheduleFiles from "./ScheduleFiles";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const ScheduleDetails = () => {
  const { id } = useParams(); // Get the schedule ID from the URL

  // Renderizar os detalhes do schedule
  return (
    <div className="container">
      <Row>
        <Col md={4}>
          <ScheduleInfo scheduleId={id} />
          <ScheduleFiles scheduleId={id}/>
        </Col>
        <Col md={8}>
          <ScheduleNotes scheduleId={id} />
        </Col>
      </Row>
    </div>
  );
};

export default ScheduleDetails;
