import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, Spinner, Card } from "react-bootstrap";
import { FaInfoCircle, FaStickyNote, FaClipboard } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import path from "../config";

function AnestethistDashboard() {
  const [NextEvents, setNextEvents] = useState([]); // Track selected schedule
  const [LastEvents, setLastEvents] = useState([]); // Track selected schedule
  const [CurrentEvents, setCurrentEvents] = useState([]); // Track selected schedule
  const [username, setUsername] = useState("User"); // Placeholder for logged-in user's name
  const [loading, setLoading] = useState(true); // Loading state

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await fetch(path + "user/GetUserName", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch username");
        }

        const data = await response.json();
        setUsername(data.username);
      } catch (error) {
        console.error(error);
        setUsername("Utilizador");
      } finally {
        setLoading(false);
      }
    };

    const fetchLastEvents = async () => {
      try {
        const response = await fetch(path +
          "sche/GetAnesthetistPastEvents",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch last events");
        }

        const data = await response.json();
        setLastEvents(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchNextEvents = async () => {
      try {
        const response = await fetch(path +
          "sche/GetAnesthetistNextEvents",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch last events");
        }

        const data = await response.json();
        setNextEvents(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchcurrentEvents = async () => {
      try {
        const response = await fetch(path +
          "sche/GetAnesthetistCurrentEvents",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch last events");
        }

        const data = await response.json();
        setCurrentEvents(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsername();
    fetchLastEvents();
    fetchNextEvents();
    fetchcurrentEvents();
  }, []);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="container">
      <h2 style={{ marginBottom: "40px" }} className="fw-bold textVet">Bem vindo, {username}!</h2>
      <Row>
        <Col md={4}>
          <h3 className="event-header">Próximos Eventos</h3>
          <ListGroup>
            {NextEvents.length > 0 ? (
              <Row className="g-3">
              {NextEvents.map((event) => (
                <Col xs={12} sm={6} md={4} lg={5}>
                <Card key={event.id} className="shadow-sm border-0 rounded-lg h-100">
                <Card.Body className="d-flex flex-column">

                    {/* Cliente Associado */}
                    {event.associatedwithuserId !== null && (
                      <div className="mb-3">
                        <div>
                          <strong>Cliente:</strong>{" "}
                          </div>
                          <div className="text-muted ms-2">
                            {event.creator.username}
                          </div>
                        </div>
                    )}

                    {/* Data e Horários */}
                    <div>
                          <div className="text-muted fw-bold" style={{ fontSize: "0.9rem" }}>
                            {new Date(event.startDate).toLocaleDateString()}
                          </div>
                          <div className="text-muted fw-bold" style={{ fontSize: "0.8rem" }}>
                            {new Date(event.startDate).toLocaleTimeString()} -{" "}
                            {new Date(event.endDate).toLocaleTimeString()}
                          </div>
                        </div>


                          <div className="d-flex mt-auto justify-content-end">
                          <FaInfoCircle
                            className="mx-1 text-primary"
                            style={{ cursor: "pointer"}}
                            title="Detalhes"
                            size={24}
                            onClick={() => navigate(`/Schedule/${event.id}`)}
                          />
                          <FaStickyNote
                            className="mx-1 text-secondary"
                            style={{ cursor: "pointer" }}
                            title="Notas"
                            size={24}
                          />
                          <FaClipboard
                            className="mx-1 text-success"
                            style={{ cursor: "pointer" }}
                            title="Revisão"
                            size={24}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
              ))}
              </Row>
            ) : (
              <Card>
                <Card.Body className="d-flex justify-content-between align-items-center">
                  Sem eventos disponiveis.
                </Card.Body>
              </Card>
            )}
          </ListGroup>
        </Col>

        <Col md={4}>
          <h3 className="event-header">Eventos a decorrer</h3>
          <ListGroup>
            {CurrentEvents.length > 0 ? (<Row className="g-3">
              {CurrentEvents.map((event) => (
                <Col xs={12} sm={6} md={4} lg={5}>
                <Card key={event.id} className="shadow-sm border-0 rounded-lg h-100">
                <Card.Body className="d-flex flex-column">

                    {/* Cliente Associado */}
                    {event.associatedwithuserId !== null && (
                      <div className="mb-3">
                        <div>
                          <strong>Cliente:</strong>{" "}
                          </div>
                          <div className="text-muted ms-2">
                            {event.creator.username}
                          </div>
                        </div>
                    )}

                    {/* Data e Horários */}
                    <div>
                          <div className="text-muted fw-bold" style={{ fontSize: "0.9rem" }}>
                            {new Date(event.startDate).toLocaleDateString()}
                          </div>
                          <div className="text-muted fw-bold" style={{ fontSize: "0.8rem" }}>
                            {new Date(event.startDate).toLocaleTimeString()} -{" "}
                            {new Date(event.endDate).toLocaleTimeString()}
                          </div>
                        </div>


                          <div className="d-flex mt-auto justify-content-end">
                          <FaInfoCircle
                            className="mx-1 text-primary"
                            style={{ cursor: "pointer"}}
                            title="Detalhes"
                            size={24}
                            onClick={() => navigate(`/Schedule/${event.id}`)}
                          />
                          <FaStickyNote
                            className="mx-1 text-secondary"
                            style={{ cursor: "pointer" }}
                            title="Notas"
                            size={24}
                          />
                          <FaClipboard
                            className="mx-1 text-success"
                            style={{ cursor: "pointer" }}
                            title="Revisão"
                            size={24}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
              ))}
              </Row>
            ) : (
              <Card>
                <Card.Body className="d-flex justify-content-between align-items-center ">
                  Sem eventos a decorrer.
                </Card.Body>
              </Card>
            )}
          </ListGroup>
        </Col>

        <Col md={4}>
          <h3 className="event-header">Últimos eventos</h3>
          <ListGroup>
            {LastEvents.length > 0 ? (
              <Row className="g-3">
              {LastEvents.map((event) => (
                <Col xs={12} sm={6} md={4} lg={5}>
                <Card key={event.id} className="shadow-sm border-0 rounded-lg h-100">
                <Card.Body className="d-flex flex-column">

                    {/* Cliente Associado */}
                    {event.associatedwithuserId !== null && (
                      <div className="mb-3">
                        <div>
                          <strong>Cliente:</strong>{" "}
                          </div>
                          <div className="text-muted ms-2">
                            {event.creator.username}
                          </div>
                        </div>
                    )}

                    {/* Data e Horários */}
                    <div>
                          <div className="text-muted fw-bold" style={{ fontSize: "0.9rem" }}>
                            {new Date(event.startDate).toLocaleDateString()}
                          </div>
                          <div className="text-muted fw-bold" style={{ fontSize: "0.8rem" }}>
                            {new Date(event.startDate).toLocaleTimeString()} -{" "}
                            {new Date(event.endDate).toLocaleTimeString()}
                          </div>
                        </div>


                          <div className="d-flex mt-auto justify-content-end">
                          <FaInfoCircle
                            className="mx-1 text-primary"
                            style={{ cursor: "pointer"}}
                            title="Detalhes"
                            size={24}
                            onClick={() => navigate(`/Schedule/${event.id}`)}
                          />
                          <FaStickyNote
                            className="mx-1 text-secondary"
                            style={{ cursor: "pointer" }}
                            title="Notas"
                            size={24}
                          />
                          <FaClipboard
                            className="mx-1 text-success"
                            style={{ cursor: "pointer" }}
                            title="Revisão"
                            size={24}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
              ))}
              </Row>
            ) : (
              <Card>
                <Card.Body className="d-flex justify-content-between align-items-center">
                  Sem eventos disponiveis.
                </Card.Body>
              </Card>
            )}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
}

export default AnestethistDashboard;
