// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';

// Import your translations
import translationEN from './locales/en/translation.json';
import translationPT from './locales/pt/translation.json';

// i18n configuration
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      pt: {
        translation: translationPT,
      },
    },
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false, // React already protects from XSS
    },
  });

// Function to change language and save in cookie
export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  Cookies.set('i18next', lng); // Store language preference in a cookie
};

export default i18n;
