import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import path from '../config';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');  // Add username field
  const [error, setError] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    // Password criteria: at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    
    // Validate password and set state
    setIsPasswordValid(validatePassword(newPassword));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!isPasswordValid) {
      setError('Password does not meet complexity requirements');
      return;
    }

    try {
      const response = await fetch(path + 'api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,  // Include username in the registration request
          email,
          password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('User registered successfully', data);
        // Optionally, you can redirect the user to the login page after registration
        navigate('/login');
      } else {
        setError(data.message || 'An error occurred during registration');
      }
    } catch (error) {
      setError('An error occurred: ' + error.message);
    }
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Image 
            src="./assets/camera.png" 
            fluid 
            alt="description of image"
          />
        </Col>
        <Col md={6}>
          <Card className="p-4 shadow-lg border-0 rounded-3">
            <Card.Body>
              <h2 className="mb-4 fw-bold textVet">Registar</h2>
              {error && <p className="text-danger text-center">{error}</p>}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicUsername" className="mb-3">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Introduzir Nome"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="rounded px-3 py-2"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Endereço de Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Introduzir endereço de email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="rounded px-3 py-2"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Palavra-chave</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Introduzir palavra-chave"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    className="rounded px-3 py-2"
                  />
                  {!isPasswordValid && password && (
                    <p className="text-danger">
                      Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.
                    </p>
                  )}
                </Form.Group>

                <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
                  <Form.Label>Confirmar Palavra-chave</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirmar palavra-chave"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="rounded px-3 py-2"
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Registar como novo cliente
                </Button>
              </Form>
              <div className="text-center mt-3">
                Já tens uma conta? <Link to="/login">Entrar</Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
